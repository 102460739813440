<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="空登録" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>
        <div class="l-contents l-box">
          <p class="l-box">引き渡し日を設定せずに予約登録します。</p>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>予約件数</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <div class="c-radio">
                  <input
                    id="is-single-true"
                    type="radio"
                    name="is-single"
                    :value="true"
                    v-model="isSingleManifest"
                    v-on:change="resetSelectNumOfManifest"
                  />
                  <label class="c-radio__label" for="is-single-true">
                    <span class="c-radio__box"></span>
                    1件
                  </label>
                </div>
                <div class="c-radio">
                  <input
                    id="is-single-false"
                    type="radio"
                    name="is-single"
                    :value="false"
                    v-model="isSingleManifest"
                  />
                  <label class="c-radio__label" for="is-single-false">
                    <span class="c-radio__box"></span>
                    複数
                  </label>
                </div>
                <div
                  class="l-flex cols"
                  :class="{ disabled: isSingleManifest }"
                >
                  <Selectbox
                    class="sm"
                    :class="{
                      hasErr:
                        validate.countInfo.errMsg &&
                        !validate.countInfo.isValid,
                    }"
                    name="other-route-num"
                    :value.sync="selectNumOfManifest"
                    :validation="validateNumber"
                    :isValid.sync="validate.countInfo.isValid"
                  >
                    <option
                      v-for="n in getReserveNumber"
                      :key="'intv' + n"
                      :value="n"
                    >
                      {{ n }}
                    </option>
                  </Selectbox>
                  <span>件</span>
                </div>
              </div>
              <div v-if="!validate.countInfo.isValid" class="c-input__errMsg">
                {{ validate.countInfo.errMsg }}
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>事業場</label>
            </div>
            <div class="c-inputWrap__items">
              <label
                v-if="storeList.length === 1"
              >{{ storeList[0].name }}</label>
              <Selectbox
                v-else
                name="other-route-num"
                :value.sync="form.generateStoreId"
                :validation="validateJustBlank"
                :isValid.sync="validate.generateStoreId.isValid"
                :errMsg.sync="validate.generateStoreId.errMsg"
              >
                <option
                  v-for="item in storeList"
                  :key="'item-' + item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </Selectbox>
            </div>
          </div>
        </div>
      </div>
      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="formHandler">登録</div>
        </div>
      </div>

      <!--登録確認のモーダル-->
      <div class="c-modal" v-show="isDisplayConfirm">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__body">
            マニフェストを{{ form.countInfo }}件予約します。<br />
            よろしいですか？
          </div>
          <ModalFooterButtons :processing="processing">
            <div class="c-btn secondary small" @click="isDisplayConfirm = false">戻る</div>
            <div class="c-btn primary small" @click="confirmHandler">はい</div>
          </ModalFooterButtons>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import ErrorMessage from "@/components/parts/ErrorMessage";
import Selectbox from "@/components/parts/input/Selectbox";
import common from "@/mixin/common";
import validation from "@/mixin/validation";
import callApi from "./mixins/callApi";
import formValidation from "./mixins/form/formValidation";
import { API_URL, COOKIE_INFO } from "@/const/index";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "manifest-temporary-input",
  components: {
    ModalFooterButtons,
    ErrorMessage,
    Selectbox,
  },
  mixins: [common, validation, callApi, formValidation],
  data() {
    return {
      isDisplayConfirm: false,
      isSingleManifest: true, // 予約件数ラジオ
      selectNumOfManifest: null, // マニフェスト件数
      // post用
      form: {
        countInfo: null,
        generateStoreId: null,
      },
      errMsgs: [],
      processing: false,
    };
  },

  computed: {
    // 件数出力1~10、以降100まで10毎
    getReserveNumber() {
      const count = 100;
      let arry = [];
      for (let i = 1; i <= count; i++) {
        if (i < 10 || i % 10 === 0) {
          arry.push(i);
        }
      }
      return arry;
    },
  },

  methods: {
    postForm() {
      this.processing = true; // 2重submit防止
      axios
        .post(API_URL.MANIFEST.EMPTY_CREATE, this.form)
        .then(() => {
          this.processing = false; // 2重submit解除
          this.$cookies.set(COOKIE_INFO.MESSAGE, "マニフェストを予約しました。");
          this.$router.push("/manifests/all");
        })
        .catch((err) => {
          this.processing = false; // 2重submit解除
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.isDisplayConfirm = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    // フォームの登録押下
    formHandler() {
      this.errMsgs = [];

      if (!this.runValidate()) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        this.isDisplayNextModal = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      this.form.countInfo = this.isSingleManifest
        ? 1
        : this.selectNumOfManifest;
      this.isDisplayConfirm = true;
    },

    // モーダルの登録押下
    confirmHandler() {
      this.postForm();
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },
    resetSelectNumOfManifest() {
      this.selectNumOfManifest = null
    },
  },
  mounted() {
    this.getStoreListApi().then(() => {
      if (this.storeList.length === 1) {
        this.validate.generateStoreId.isValid = true;
        this.form.generateStoreId = this.storeList[0].id;
      }
    });
  },
};
</script>
