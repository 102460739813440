import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      storeList: [],
    };
  },

  methods: {
    // 事業場選択肢取得API
    getStoreListApi() {
      return axios
        .get(API_URL.SCHEDULE.GENERATE_STORE)
        .then((res) => {
          const generateCompanyList = res.data.generateCompanyList;
          generateCompanyList.map((gsList) => {
            gsList.generateStoreList.map((sStore) => {
              this.storeList = [...this.storeList, sStore];
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
