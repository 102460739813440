export default {
  data() {
    return {
      validate: {
        countInfo: { isValid: true, errMsg: "" },
        generateStoreId: { isValid: true, errMsg: "" },
      },
    };
  },
  methods: {
    check() {
      const v = this.validate;
      let isValidForm = true;

      if (!this.isSingleManifest && !v.countInfo.isValid) {
        v.countInfo.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.generateStoreId.isValid) {
        v.generateStoreId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      return isValidForm;
    },

    runValidate() {
      return this.check();
    },
  },
};
